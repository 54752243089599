<template>
  <div class="modal fade" id="goal-modal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-scrollable">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">{{ title }}</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <table class="table table-sm table-bordered table-striped">
            <thead>
            <tr>
              <th>Week Start</th>
              <th>Week End</th>
              <th>Week No.</th>
              <th v-if="type === 'production'">Production Goal</th>
              <th v-if="type === 'sc'">Scorecard Goal</th>
              <th v-if="type === 'job'">Job Goal</th>
              <th v-if="type === 'wow'">Wow Goal</th>
            </tr>
            </thead>

            <tbody>
            <tr v-for="goal in goals" :key="'goal_'+goal.id">
              <td>{{ goal.date }}</td>
              <td>{{ goal.end_date }}</td>
              <td>{{ goal.week }}</td>
              <td>
                <input type="text"
                       v-if="type === 'production'"
                       :disabled="loading"
                       class="form-control form-control-sm"
                       v-model="goal.pay_goal">

                <input type="text"
                       v-if="type === 'sc'"
                       :disabled="loading"
                       class="form-control form-control-sm"
                       v-model="goal.scorecard_goal">

                <input type="text"
                       v-if="type === 'job'"
                       :disabled="loading"
                       class="form-control form-control-sm"
                       v-model="goal.job_goal">

                <input type="text"
                       v-if="type === 'wow'"
                       :disabled="loading"
                       class="form-control form-control-sm"
                       v-model="goal.wows_goal">
              </td>
            </tr>
            </tbody>
          </table>
        </div>
        <div class="modal-footer">
          <button type="button"
                  @click="saveGoals"
                  class="btn btn-primary">Save Changes</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      modal: null,
      goals: [],
      type: '',
    }
  },
  mounted() {
    this.modal = new window.bootstrap.Modal(document.getElementById('goal-modal'))
  },
  computed: {
    title() {
      if (this.type === 'production') {
        return 'Production Goal History';
      } else if (this.type === 'sc') {
        return 'Scorecard Goal History';
      } else if (this.type === 'job') {
        return 'Job Goal History';
      } else if (this.type === 'wow') {
        return 'Wow Goal History';
      } else {
        return '';
      }
    }
  },
  methods: {
    show(type, goals) {
      this.goals = [...goals];
      this.type = type;
      this.modal.show();
    },
    hide() {
      this.goals = [];
      this.modal.hide();
    },
    saveGoals() {
      this.loading = true;

      let payGoal = this.goals.map(g => g.pay_goal);
      let wowGoal = this.goals.map(g => g.wows_goal);
      let scGoal = this.goals.map(g => g.scorecard_goal);
      let jobGoal = this.goals.map(g => g.job_goal);
      let ids = this.goals.map(g => g.id);

      this.axios.post('/technicians/goals', {
          ids: ids,
          pay_goal: payGoal,
          scorecard_goal: scGoal,
          wows_goal: wowGoal,
          job_goal: jobGoal,
        })
        .then(() => {
          this.showSuccessMsg("Goal Updated!");
          this.hide();
        })
        .catch(() => {
          this.showFailMsg("Something Wrong!");
        })
        .finally(() => {
          this.loading = false;
        })
    }
  }
}
</script>