<template>
  <!-- Content Header (Page header) -->
  <div class="content-header">
    <div class="container-fluid">
      <div class="row mb-2">
        <div class="col-sm-6">
          <h1 class="m-0">{{ title }}</h1>
        </div><!-- /.col -->
      </div><!-- /.row -->
    </div><!-- /.container-fluid -->
  </div>
  <!-- /.content-header -->

  <section class="content">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <h3 class="card-title">Wow Information</h3>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-md-6">

<!--                  <div class="form-group">
                    <label>Company</label>
                    <select :class="['form-control', {'is-invalid': errorFor('company_id')}]"
                            v-model="form.company_id">
                      <option value="">Select Company</option>
                      <option :value="company.id"
                              v-for="company in companies"
                              :key="'company_'+company.id">{{ company.name }}</option>
                    </select>

                    <v-errors :errors="errorFor('company_id')" />
                  </div>-->

                  <div class="form-group">
                    <label>Technician</label>
                    <select :class="['select2 form-control']"
                            id="technicians"
                            :disabled="$route.name === 'wow_edit'"
                            multiple="multiple"
                            data-placeholder="Select Technicians"
                            style="width: 100%;">
                      <option v-for="(technician, i) in technicians"
                              :key="'tech_'+i"
                              :value="technician.id">{{ technician.name }}</option>
                    </select>

                    <v-errors :errors="errorFor('technicians')" />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label>Truck</label>

                    <select :class="['form-control', {'is-invalid': errorFor('equipment_id')}]"
                            v-model="form.equipment_id">
                      <option value="">Select Truck</option>
                      <option :value="truck.id"
                              v-for="(truck, i) in trucks"
                              :key="'truck_'+i">{{ truck.name }}</option>
                    </select>
                    <v-errors :errors="errorFor('equipment_id')" />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label>Type</label>
                    <select :class="['form-control', {'is-invalid': errorFor('type')}]"
                            v-model="form.type">
                      <option value="">Select Type</option>
                      <option value="Home Stars">Home Stars</option>
                      <option value="Google Reviews">Google Reviews</option>
                      <option value="5 Around">5 Around</option>
                      <option value="Commercial Job">Commercial Job</option>
                    </select>

                    <v-errors :errors="errorFor('type')" />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label>Review</label>
                    <input type="text"
                           :class="['form-control', {'is-invalid': errorFor('review')}]"
                           v-model="form.review">
                    <v-errors :errors="errorFor('review')" />
                  </div>
                </div>
              </div>
            </div>
            <div class="card-footer">
              <button type="submit"
                      class="btn btn-success mr-1"
                      @click.prevent="submit"
                      :disabled="loading">Save</button>

              <router-link :to="{name: 'wow'}"
                           class="btn btn-secondary">Cancel</router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      title: 'Add Wow',
      technicians: [],
      companies: [],
      charities: [],
      trucks: [],
      form: {
        technicians: [],
        company_id: '',
        equipment_id: '',
        type: '',
        review: '',
      }
    }
  },
  async created() {
    // this.loadCompanies();
    // this.loadCharities();

    if (this.$route.name === 'wow_edit') {
      await this.axios.get('/wows/'+this.$route.params.id)
        .then((response) => {
          let wow = response.data.data;
          // this.form.company_id = wow.company_id;
          this.form.type = wow.type;
          this.form.review = wow.review;
          this.form.equipment_id = wow.equipment_id;

          if (wow.technician_id) {
            this.form.technicians.push(wow.technician_id);
          }
        })

      this.title = 'Edit Wow';
    }
  },
  mounted() {
    this.getTechnicians();
    this.loadTrucks();
  },
  methods: {
    loadTrucks() {
      this.axios.get('/trucks')
          .then((response) => {
            this.trucks = response.data.data;
          });
    },
    /*loadCompanies() {
      this.companies = [];

      this.axios.get('/companies')
        .then(res => {
          this.companies = res.data.data;
        })
    },*/
    /*loadCharities() {
      this.charities = [];

      this.axios.get('/charities')
        .then(res => {
          this.charities = res.data.data;
        })
    },*/
    initSelect2() {
      let self = this;
      window.$('.select2').select2()

      window.$('#technicians').val(self.form.technicians).trigger('change');

      window.$('#technicians').on('change.select2', function () {
        self.form.technicians = window.$(this).val();
      });
    },
    getTechnicians() {
      this.axios.get('/technicians')
        .then((response) => {
          this.technicians = response.data.data;

          setTimeout(() => {
            this.initSelect2();
          }, 100);
        });
    },
    submit() {
      this.allErrors = null;
      this.loading = true;

      if (this.$route.name === 'wow_edit') {
        this.axios.patch('/wows/'+this.$route.params.id, this.form)
          .then(() => {
            this.$router.push({name: 'wow'});
            this.showSuccessMsg('Updated Successfully!')
          })
          .catch((err) => {
            this.allErrors = err.response.data.errors;
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        this.axios.post('/wows', this.form)
          .then(() => {
            this.$router.push({name: 'wow'});
            this.showSuccessMsg('Added Successfully!')
          })
          .catch((err) => {
            this.allErrors = err.response.data.errors;
          })
          .finally(() => {
            this.loading = false;
          });
      }
    }
  }
}
</script>