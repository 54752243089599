<template>
  <div class="modal fade" id="weekly-pay-histories-modal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-scrollable">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Weekly Pay History</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <table class="table table-sm table-bordered table-striped">
            <thead>
            <tr>
              <th>Week Start</th>
              <th>Week End</th>
              <th>Week No.</th>
              <th>Weekly Pay</th>
            </tr>
            </thead>

            <tbody>
            <tr v-for="history in histories" :key="'history_'+history.id">
              <td>{{ history.start_date }}</td>
              <td>{{ history.end_date }}</td>
              <td>{{ history.week }}</td>
              <td>
                <input type="text"
                       :disabled="loading"
                       class="form-control form-control-sm"
                       v-model="history.weekly_pay">
              </td>
            </tr>
            </tbody>
          </table>
        </div>
        <div class="modal-footer">
          <button type="button"
                  @click="save"
                  class="btn btn-primary">Save Changes</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      modal: null,
      histories: [],
    }
  },
  mounted() {
    this.modal = new window.bootstrap.Modal(document.getElementById('weekly-pay-histories-modal'))
  },
  methods: {
    show(histories) {
      this.histories = [...histories];
      this.modal.show();
    },
    hide() {
      this.histories = [];
      this.modal.hide();
    },
    save() {
      this.loading = true;

      this.axios.post('/technicians/weekly-pay/histories', this.histories)
        .then(() => {
          this.showSuccessMsg("History Updated!");
          this.hide();
        })
        .catch(() => {
          this.showFailMsg("Something Wrong!");
        })
        .finally(() => {
          this.loading = false;
        })
    }
  }
}
</script>